.App {
  text-align: center;
}

.back {
  display: flex;
  background-color: #000;
  height: 100vh;
  position: absolute;
  justify-content: center;
  width: 100%;
}

.image {
  width: 33%;
  aspect-ratio: 1;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image {
    width: 100%;
    height: 33%;
  }

  .back {
    display: flex;
    flex-direction: column;
    background-color: #000;
    justify-content: center;
  }
}

.content {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(24, 84, 144, 0.7);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.material-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #6200ee; /* Primary color */
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
}

.material-button:hover {
  background-color: #3700b3; /* Darker shade for hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.28);
}

.material-button:active {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.28);
}

.material-button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #bb86fc;
}

.text {
  color: #fefefe;
}

.disclaimer {
  color: #fefefe;
  font-size: 8px;
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}
